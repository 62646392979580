
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.SpacedList {
  > * + * {
    margin-top: spacing(2);
  }
}
