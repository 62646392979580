
      @use 'sass:math';
      @import '~@clocklimited/backline-mixins/src/backline-mixins';
      @import 'settings';
    
.Container {
  width: 100%;
  clear: both;
  position: relative;
}

.noClearfix {
  clear: none;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.gutter {
  padding-left: spacing(3);
  padding-right: spacing(3);

  @include mq($breakpoint-desktop) {
    padding-left: spacing(4);
    padding-right: spacing(4);
  }
}

// SIZES

.full {
  max-width: 100%;
}

.huge {
  max-width: spacing(156);

  @include mq($breakpoint-desktopLarge) {
    max-width: spacing(195);
  }
}

.wide {
  max-width: spacing(160);
}

.large {
  max-width: spacing(120);
}

.medium {
  max-width: spacing(100);
}

.small {
  max-width: spacing(60);
}
